
<i18n locale="th" lang="yaml" >
page.title : "สามารถเข้าถึงข้อมูลของ"
page.description : "แสดงและค้นหาศูนย์บริการของ Tiger Drone"
confirm.action.update : "ยืนยันการแก้ไขข้อมูล"
action.update.no_change : "ไม่ได้มีการแก้ไขค่า"
action.update.success: "บันทึกสำเร็จ"
confirm.action.update.content: "คุณต้องการบันทึกการแก้ไขข้อมูลหรือไม่ ?"
</i18n>

<template>
	<div class="page page-padding">
		<a-form
			:form="form"
			@submit.prevent="handleSubmit">

			<a-form-item>
				<slot name="submitSlot" :formLoading="loading">
					<a-button
						class="btn-submit right-button"
						:loading="loading"
						type="info"
						html-type="submit"
						size="large"
						icon="save">
						{{$t('confirm.action.update')}}
					</a-button>
				</slot>
			</a-form-item>
			<my-page-header
				:title="$t('page.title')"/>
			<a-form-item>
				<CompanyList
					v-if="companies.length"
					ref="selectedCompaniesRef"
					selectable
					:companies="companies"
					@idChange="selectCompany"/>
			</a-form-item>
			<a-form-item>
				<slot name="submitSlot" :formLoading="loading">
					<a-button
						class="btn-submit right-button"
						:loading="loading"
						type="info"
						html-type="submit"
						size="large"
						icon="save">
						{{$t('confirm.action.update')}}
					</a-button>
				</slot>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import axios from "axios"
import CompanyList from "@components/company/CompanyList.vue"
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import ApiError from '@/src/utils/errors/ApiError'
import _isEqual from "lodash/isEqual"

export default {
	components : {
		CompanyList,
	} ,
	mixins: [PageMixin, HasAntdFormMixin],
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	data() {
		return {
			companies : [] ,
			loading: false,
			companyBranchList: [],
			defaultCompanyBranchList: [],
			currentStateObj : {}
		}
	} ,
	mounted() {
		this.fetchData();
	} ,
	methods : {
		fetchData() {
			this.loading = true
			const id = this.$route.params.id
			axios.get(`/api/companies/${id}/branch`).then((response) => {
				this.companies = response.data.data.companies
				this.companies.forEach((company) => {
					if (company.selected) {
						this.companyBranchList.push(company.id)
						this.defaultCompanyBranchList.push(company.id)
					}
				})
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.loading = false
			})
		},
		selectCompany(id) {
			const idList = this.companyBranchList
			idList.includes(id) ? idList.splice(idList.indexOf(id), 1) : idList.push(id)
		},
		handleSubmit() {
			const oldBranchList = this.defaultCompanyBranchList
			const newBranchList = this.companyBranchList
			if (!_isEqual(newBranchList, oldBranchList)) {
				const id = this.$route.params.id
				const formData = {
					'company_branch_list' : this.companyBranchList
				}
				this.$confirm({
					title : this.$t('confirm.action.update') ,
					content : this.$t('confirm.action.update.content') ,
					okText : this.$t('common.confirm') ,
					maskClosable : true,
					onOk: ()=> {
						this.showPageLoading(true)
						axios.post(`/api/companies/${id}/branch`, formData).then((response) => {
							this.$message.success(this.$t('action.update.success'))
							this.$router.go()
						}).catch((error) => {
							this.$message.error(ApiError.getDisplayErrorMessage(this,error))
						}).finally(()=>{
							this.hidePageLoading()
						});
					} ,
				})
			} else {
				this.$message.info(this.$t('action.update.no_change'))
			}
		}
	}
}
</script>

<style lang="less" scoped>
.company-list::v-deep {
	margin-bottom : 32px;
	.company-card {
		width : 400px;
		.mobile & {
			width : 100%;
		}
	}
}
.right-button {
	float: right;
}

</style>
